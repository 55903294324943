import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link, Text } from '@xyz-school/xyz-react-frontend';
import Button from '@/ui/Button';
import PhoneField from '@/ui/PhoneField';
import Checkbox from '@/ui/Checkbox';

import AuthPageLayout from '../AuthPageLayout';
import thumb from '../images/phone-step-thumb.png';
import thumbPhoneOnly from '../images/phone-req-step-thumb.png';

import styles from './AuthPhoneStep.scss';

export type PhoneSubmitSuccessDTO = {
    phone: string;
    verificationId: string;
    verificationEndDate?: string;
};

type Props = {
    phone: string;
    agreement: boolean;
    subscription: boolean;
    isPhoneOnly?: boolean;
    isSubmitting?: boolean;
    redirectUrl?: string | null;
    onChangePhone: (phone: string) => void;
    onChangeAgreement: (agreement: boolean) => void;
    onChangeSubscription: (subscription: boolean) => void;
    onSubmit: () => void;
};

const AuthPhoneStep = ({
    phone,
    agreement,
    subscription,
    isPhoneOnly = false,
    isSubmitting,
    redirectUrl,
    onChangePhone,
    onChangeAgreement,
    onChangeSubscription,
    onSubmit,
}: Props) => {
    const { t } = useTranslation(['auth']);

    const handlePhoneChange = (value: string) => {
        onChangePhone(value);
    };

    const handleAgreementChange = () => {
        onChangeAgreement(!agreement);
    };

    const handlSubsriptionChange = () => {
        onChangeSubscription(!subscription);
    };

    return (
        <AuthPageLayout
            image={isPhoneOnly ? thumbPhoneOnly : thumb}
            title={isPhoneOnly ? t('PHONE_STEP_REQUIRED_TITLE') : t('PHONE_STEP_TITLE')}
            description={isPhoneOnly ? t('PHONE_STEP_REQUIRED_DESCRIPTION') : t('PHONE_STEP_DESCRIPTION')}
            info={
                <Text size="small" color="label" align="center">
                    Код придёт в Telegram. Если нет аккаунта по этому номеру, то в смс
                </Text>
            }
        >
            <PhoneField className={styles.input} value={phone} onChange={handlePhoneChange} />

            <Button
                styleType="default"
                size="large"
                isFullWidth
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={onSubmit}
            >
                {t('PHONE_STEP_NEXT_BTN_TEXT')}
            </Button>

            <div className={styles.agreementsWrap}>
                <Checkbox isChecked={agreement} onChange={handleAgreementChange} className={styles.agreementCheckbox}>
                    {t('PHONE_STEP_AGREEMENT_TEXT')}{' '}
                    <Link to="https://www.school-xyz.com/privacy-policy" className={styles.agreementLink}>
                        {t('PHONE_STEP_AGREEMENT_LINK_TEXT')}
                    </Link>
                </Checkbox>
                <Checkbox isChecked={subscription} onChange={handlSubsriptionChange}>
                    {t('PHONE_STEP_SUBSCRIPTION_TEXT')}
                </Checkbox>
            </div>

            {!isPhoneOnly && (
                <>
                    <Text align="center" className={styles.discordDescription}>
                        {t('DISCORD_AUTH_DESCRIPTION')}
                    </Text>
                    <Button
                        styleType="accent"
                        linkTo={
                            redirectUrl ? `/accounts/discord/login/?next=${redirectUrl}` : '/accounts/discord/login/'
                        }
                        icon="discord"
                        size="large"
                        isFullWidth
                    />
                </>
            )}
        </AuthPageLayout>
    );
};

export default AuthPhoneStep;
